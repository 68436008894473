import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"

const NotFoundArea = styled.div`
  max-width: 600px;
  margin: 40px auto 50px;
  padding: 0 20px;
  text-align: center;
  h1 {
  }
  p {
  }
`;
const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404 Not Found" />
      <NotFoundArea>
        <h1>404 Not Found</h1>
        <p>ご指定のページは見つかりませんでした。</p>
      </NotFoundArea>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
